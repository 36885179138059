import React, { useEffect } from 'react';

function App() {

  useEffect(() => {
     window.location.href = 'https://edayaapp.com/guestweb/';
  }, []);  

  return (
    
    <div className="App">
    </div>
  );
}

export default App;
